:root {
  --white: #ffffff;
  --black: #000000;
  --near-black: #131313;
  --alto: #c0c0c0;
}

*{
  font-family: 'Roboto Mono', monospace;
}

html {
  width: 100%;
  color: var(--black);
  background-color: var(--black);
  min-height: 100%;
  background: url('./images/background.svg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
}

.content {
  flex: 1 0 auto;
}

.bocachica-logo {
  display: block;
  margin: 0 auto;
  width: 16.5rem;
  height: 6.2rem;
  margin-top: 11.25rem;
}

.block-choice-platform {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 0.75rem 1rem;
}

.btn-choice-platform {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  border: none;
  border-radius: 50px;
  padding: 0.25em 0.5em;
  width: 13.625rem;
  height: 3.5rem;
  margin: 3rem 1rem;
  cursor: pointer;
  min-width: 210px;
}

.btn-choice-platform p {
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 32px;
  margin: 0 0.5em;
  color: var(--black);
}

.btn-choice-platform img {
  width: 10px;
  height: 18px;
  margin-right: 0.7em;
}

.block-event-timeline {
  position: relative;
  margin-top: 1.875rem;
  box-sizing: border-box;
}

.block-event-timeline h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.063rem;
  line-height: 22px;
  text-align: center;
  color: var(--white);
}

.event-timeline-slider {
  margin-top: 1.25rem;
  overflow: hidden;
}

.event-timeline-block {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.event-timeline-block-body {
  width: 100%;
}

.swiper-slide:first-child {
  margin-left: 10rem;
}

.swiper-slide {
  display: flex;
  align-items: flex-end;
  width: 21em;
  height: 9em;
}

.event-timeline-slider-information {
  display: flex;
  width: 19em;
  height: 10em;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: -9em;
  top: -8.5em;
}

.event-timeline-slider-information h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 18px;
  text-align: center;
  color: var(--white);
  margin: 0;
}

.event-timeline-text {
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 16px;
  text-align: center;
  color: var(--white);
  margin-top: 0.313em;
  margin-bottom: 0.625em;
}

.event-timeline-date {
  width: 4.125em;
  height: 1.25em;
  border-radius: 8px;
  border: none;
  background-color: var(--white);
}

.event-timeline-date p {
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 16px;
  text-align: center;
  color: var(--near-black);
  margin: 0.125em 0;
}

.progress-bar {
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
}

.progress-bar img {
  width: 16px;
  height: 16px;
}

footer {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 2.375rem 0;
}

.logo-HAPI {
  display: flex;
  flex-direction: row;
  margin-left: 2.75rem;
}

.logo-HAPI a {
  display: flex;
  align-items: center;
}

.logo-HAPI a img {
  width: 48px;
  height: 48px;
}

.logo-HAPI p {
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 16px;
  color: var(--white);
  text-align: center;
  margin: auto 0.5rem;
}

.block-social-networks {
  display: flex;
  flex-direction: row;
  margin-right: 1.25rem;
}

.block-social-networks a {
  display: flex;
  align-items: center;
  margin: 0 1.25em;
}

.block-social-networks a img {
  width: 48px;
  height: 48px;
}

@media only screen and (max-width: 64.063em) and (max-height: 85.375em) and (min-width: 31.25em) and (min-height: 62.5em) and (orientation: portrait) {
  .block-choice-platform {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 12rem;
  }

  .btn-choice-platform {
    margin: 8px 0;
  }
}

@media only screen and (max-width: 31.188em) and (orientation: portrait) {
  .bocachica-logo {
    margin-top: 1rem;
  }

  .block-choice-platform {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 4.5rem;
  }

  .btn-choice-platform {
    margin: 8px 0;
  }

  footer {
    padding: 1.5rem 0;
  }

  .logo-HAPI {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
  }

  .block-social-networks a {
    margin: auto 0.625em;
  }

  .block-social-networks a img {
    width: 32px;
    height: 32px;
  }
}

@media only screen and (max-width: 20.5em) and (max-height: 50em) and (orientation: portrait) {
  .logo-HAPI p {
    margin: auto 0.2rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-height: 26.25em) and (orientation: landscape) {
  .bocachica-logo {
    margin-top: 5rem;
  }

  footer {
    padding: 2rem 0;
  }
}
